'use strict';

app.productPage = {
	initEl: '#js-fp-product',
	sectionEl: '#js-fp-product > section',
	duration: 500,
	prevBtnEl: '.js-move-up',
	nextBtnEl: '.js-move-down',
	init: function init() {
		var _this = this;

		app.common.initScript('fullpage.min', 'fullpage', function () {
			if ($(_this.initEl).length) {
				_this.countElemInPart = _this.countElemInFirstPart();
				_this.disabledTargetButtons();
				_this.run();
				_this.goToSections();
			}
		});
		app.common.initStyle('fullpage.min');
		app.common.initScript('jquery.matchHeight-min', 'matchHeight', function () {
			var $item = $('.product-parts__set-item');
			if ($item.length) {
				_this.equalHeight($item);
			}
		});
	},
	run: function run() {
		var self = this;
		var scrollStep = void 0;
		$(this.initEl).fullpage({
			sectionSelector: self.sectionEl,
			anchors: self.historyAnchors(),
			recordHistory: true,
			keyboardScrolling: false,
			touchSensitivity: 10,
			verticalCentered: false,
			scrollingSpeed: self.duration,
			licenseKey: '11111111-11111111-11111111-11111111',
			navigation: false,
			lazyLoading: false,
			afterLoad: function afterLoad(origin, destination) {
				$(self.sectionEl).removeClass('animation-end');

				// Step scroll
				clearTimeout(scrollStep);
				scrollStep = setTimeout(function () {
					if (destination.index === 1 || destination.index === self.countElemInPart + 1) {
						// If menu or last element of first group
						fullpage_api.setAllowScrolling(true, 'up');
					} else if (destination.index === 2 || destination.index === self.countElemInPart + 2) {
						// If first element of first element or first element of second group
						fullpage_api.setAllowScrolling(true, 'down');
					} else {
						fullpage_api.setAllowScrolling(true);
					}
				}, self.duration);

				var $destinationEl = $(destination.item);

				$destinationEl.addClass('animation-end');
				if (destination.isLast && $destinationEl.hasClass('fp-auto-height')) {
					$(origin.item).addClass('animation-end');
				}
			},
			onLeave: function onLeave(origin, destination) {
				fullpage_api.setAllowScrolling(false);

				$(self.sectionEl).removeClass('animation-start');

				var $destinationEl = $(destination.item);
				var $originEl = $(origin.item);

				$destinationEl.addClass('animation-start');
				if (destination.isLast && $destinationEl.hasClass('fp-auto-height')) {
					$originEl.addClass('animation-start');
				}
			}
		});
	},
	historyAnchors: function historyAnchors() {
		var anchors = [];

		$.each($(this.sectionEl), function () {
			anchors.push($(this).attr('data-anchor'));
		});

		return anchors;
	},
	goToSections: function goToSections() {
		$(this.sectionEl).on('click', this.prevBtnEl, function (e) {
			e.preventDefault();
			if (!$(this).hasClass('disabled')) {
				if (fullpage_api) {
					fullpage_api.moveSectionUp();
				}
			}
		}).on('click', this.nextBtnEl, function (e) {
			e.preventDefault();
			if (!$(this).hasClass('disabled')) {
				if (fullpage_api) {
					fullpage_api.moveSectionDown();
				}
			}
		});
	},
	disabledTargetButtons: function disabledTargetButtons() {
		// First and last section in first group
		this.disabledBtn($(this.sectionEl).eq(2).find(this.prevBtnEl));
		this.disabledBtn($(this.sectionEl).eq(this.countElemInPart + 1).find(this.nextBtnEl));
		// First and last section in second group
		this.disabledBtn($(this.sectionEl).eq(this.countElemInPart + 2).find(this.prevBtnEl));
		this.disabledBtn($(this.sectionEl).last().find(this.nextBtnEl));
	},
	disabledBtn: function disabledBtn($btn) {
		$btn.addClass('disabled');
	},
	countElemInFirstPart: function countElemInFirstPart() {
		return $('.product-parts-list-js').eq(0).find('a').length;
	},
	equalHeight: function equalHeight($el) {
		$el.matchHeight();
	}
};