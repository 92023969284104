'use strict';

app.awardSlider = {
	sliderElement: '.js-awards-slider',
	slidesPerView: 3,
	init: function init() {
		var _this = this;

		app.common.initScript('swiper.min', 'Swiper', function () {
			_this.runSlider();
		});
		app.common.initStyle('swiper.min');
		app.common.initScript('jquery.matchHeight-min', 'matchHeight', function () {
			// $(this.sliderElement).find('.awards-widget__figure').matchHeight();
			$(_this.sliderElement).find('.awards-widget__text').matchHeight();
		});
	},
	runSlider: function runSlider() {
		var self = this;
		var $slider = $(this.sliderElement);

		if ($slider.length) {
			$.each($slider, function () {
				var $curSlider = $(this);
				var $nextEl = $curSlider.find('.js-awards-slider__button-next');
				var $prevEl = $curSlider.find('.js-awards-slider__button-prev');

				var sliderInstance = new Swiper($curSlider.find('.swiper-container'), {
					init: false,
					loop: false,
					slidesPerView: self.slidesPerView,
					slidesPerGroup: 1, // self.slidesPerView
					spaceBetween: 0,

					navigation: {
						nextEl: $nextEl,
						prevEl: $prevEl
					},

					on: {
						slideChangeTransitionEnd: function slideChangeTransitionEnd() {
							var $img = $(sliderInstance.visibleSlides).find('.js-lazy-load');
							if ($img.filter('.loaded').length !== $img.length && app.lazyLoadGlobalInstance) {
								app.lazyLoadGlobalInstance.update();
							}
						}
					}
				});

				sliderInstance.on('init', function () {
					$curSlider.addClass('is-loaded');
				});

				sliderInstance.init();
			});
		}
	}
};