"use strict";

app.common.init();
app.common.setViewport();
app.common.initLazyLoad();
app.home.init();
app.scan.init();
app.macro.init();
app.micro.init();
app.projectsRegions.init();
app.services.init();
app.philosophy.init();
app.productPage.init();
app.popupCustom.init();
app.productDetail.init();
app.formElement.phoneInput();
app.vote.init();
app.awardSlider.init();