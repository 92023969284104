'use strict';

app.productDetail = {
	openEl: '.js-open-presentation',
	closeEl: '.js-close-presentation',
	popupEl: '.js-detail-popup',
	contentEl: '.js-detail-content',
	sceneEl: '.js-detail-scene',
	preventCloseEl: '.js-prevent-close',
	mods: {
		ACTIVE_INST: 'activeProductDetail',
		isOpen: 'is-open'
	},
	hasOpened: false,
	init: function init() {
		var _this = this;

		app.common.initScript('EasePack.min', 'EasePack', function () {
			app.common.initScript('gsap.min', 'gsap', function () {
				if ($(_this.openEl).length) {
					_this.eventsPopup();
					_this.closeOnEsc();
					_this.removeByClickOutside();
				}
			});
		});
	},
	openPopup: function openPopup($el) {
		if ($el && $el.length) {
			if (fullpage_api) {
				fullpage_api.setAllowScrolling(false);
			}

			$el.addClass(this.mods.isOpen).addClass(this.mods.ACTIVE_INST);

			$('html').addClass('popup-presentation_' + this.mods.isOpen);

			this.playVideo($('video', $el), $('img', $el));

			this.hasOpened = true;
		} else {
			console.warn('Element not found');
		}
	},
	closePopup: function closePopup($el) {
		$el = $el && $el.length ? $el : $('.' + this.mods.ACTIVE_INST);

		if ($el && $el.length) {
			if (fullpage_api) {
				fullpage_api.setAllowScrolling(true);
			}

			$el.removeClass(this.mods.isOpen).removeClass(this.mods.ACTIVE_INST);

			$('html').removeClass('popup-presentation_' + this.mods.isOpen);

			this.stopVideo($('video', $el), $('img', $el));

			this.hasOpened = false;
		} else {
			console.warn('Element not found');
		}
	},
	eventsPopup: function eventsPopup() {
		var self = this;

		$(self.openEl).on('click', function (e) {
			e.preventDefault();

			var id = $(this).attr('href');

			self.openPopup($(id));
		});

		$(self.closeEl).on('click', function (e) {
			e.preventDefault();

			self.closePopup();
		});
	},
	closeOnEsc: function closeOnEsc() {
		var self = this;

		$('html').keyup(function (event) {
			if (self.hasOpened && (event.key === 'Escape' || event.key === 'Esc')) {
				self.closePopup();
			}
		});
	},
	removeByClickOutside: function removeByClickOutside() {
		var self = this;

		$(self.popupEl).on('click', function (event) {
			if (self.hasOpened && !$(event.target).closest(self.preventCloseEl).length) {
				self.closePopup();
			}
		});
	},
	playVideo: function playVideo($video, $img) {
		if ($video.length) {
			var video = $video[0];
			$.each($('source', $video), function () {
				var sourceFile = $(this).attr('data-src');
				if (sourceFile && sourceFile.length) {
					$(this).attr('src', sourceFile).removeAttr('data-src').addClass('load-init');
					this.parentElement.load();
				}
			});
			var interval = setInterval(function () {
				if (video.readyState > 0) {
					clearInterval(interval);

					$video.fadeIn();
					$img.fadeOut();
					video.play();
				}
			}, 200);
		}
	},
	stopVideo: function stopVideo($video, $img) {
		if ($video.length) {
			var video = $video[0];
			var interval = setInterval(function () {
				if (video.readyState > 0) {
					clearInterval(interval);

					video.pause();
					// video.currentTime = 0;
					$video.fadeOut();
					$img.fadeIn();
				}
			}, 200);
		}
	}
};