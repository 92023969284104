'use strict';

app.formElement = {
	phoneInput: function phoneInput() {
		app.common.initScript('imask.min', 'IMask', function () {
			var elem = document.querySelectorAll('.js-type-phone');
			elem.forEach(function (el) {
				IMask(el, {
					mask: /^[0-9\\+]\d{0,12}$/,
					lazy: false, // make placeholder always visible
					placeholderChar: '_' // defaults to '_'
				});
			});
		});
	}
};