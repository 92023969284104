'use strict';

app.scan = {
	initEl: '.js-scan',
	sceneItem: '.js-scan-scene [data-scan-step]',
	control: '.js-scan-control',
	scanVideo: '.js-scan-video',
	mods: {
		active: 'active'
	},
	stepsArr: [],
	init: function init() {
		var $initEl = $(this.initEl);
		if ($initEl.length) {
			this.createStepsArr();
			this.defineActiveIdOnInit();
			this.setActiveOnInit();
			this.changeStep();
			this.moveToPrevOnEsc();
			$initEl.addClass('scan-initialized');
		}
	},
	createStepsArr: function createStepsArr() {
		var thisApp = this;

		if (!thisApp.stepsArr.length) {
			$.each($(thisApp.sceneItem), function () {
				var data = $(this).attr('data-scan-step');
				thisApp.stepsArr.push(data ? data : null);
			});
		}
	},
	defineActiveIdOnInit: function defineActiveIdOnInit() {
		var activeId = $(this.sceneItem).filter('.active').last().attr('data-scan-step');
		this.activeId = this.initialId = activeId && activeId.length ? activeId : this.stepsArr[0];
	},
	setActiveOnInit: function setActiveOnInit() {
		this.moveTo(this.activeId);
	},
	startScan: function startScan() {
		var video = $(this.scanVideo)[0];

		if (video) {
			var interval = setInterval(function () {
				if (video.readyState > 0) {
					clearInterval(interval);

					video.play();
				}
			}, 200);
		}
	},
	stopScan: function stopScan() {
		var video = $(this.scanVideo)[0];

		if (video && video.readyState > 0) {
			video.pause();
			video.currentTime = 0;
		}
	},
	endedScan: function endedScan() {
		var app = this;
		var video = $(this.scanVideo)[0];

		if (video) {
			video.onended = function () {
				app.reset();
			};
		}
	},
	examineOnCompleted: function examineOnCompleted() {
		var app = this;
		var timeoutStart = void 0;
		var timeoutStop = void 0;

		$(this.initEl).toggleClass('scan-completed', this.completed);

		if (this.completed) {
			clearTimeout(timeoutStart);

			timeoutStart = setTimeout(function () {
				app.startScan();
			}, 500);
		} else {
			clearTimeout(timeoutStop);

			timeoutStop = setTimeout(function () {
				app.stopScan();
			}, 800);
		}
	},
	moveTo: function moveTo(id) {
		this.activeId = id || this.initialId;
		var activeIndex = this.stepsArr.indexOf(this.activeId);
		var $control = $(this.control);

		$control.removeClass(this.mods.active);

		$('a', $control).filter('[href="#' + this.stepsArr[activeIndex + 1] + '"]').closest(this.control).addClass(this.mods.active);

		$('[data-scan-step]').removeClass(this.mods.active).filter('[data-scan-step="' + this.activeId + '"]').addClass(this.mods.active);

		$(this.initEl).attr('data-scan-active-step-index', activeIndex + 1);

		this.completed = activeIndex === this.stepsArr.length - 1;
		this.examineOnCompleted();
	},
	moveToPrev: function moveToPrev() {
		this.moveTo(this.stepsArr[this.stepsArr.indexOf(this.activeId) - 1]);
	},
	changeStep: function changeStep() {
		var thisApp = this;
		var $initEl = $(thisApp.initEl);

		$initEl.on('click', this.control + ' a', function (event) {
			event.preventDefault();
			thisApp.moveTo($(this).attr('href').substr(1));
		});
	},
	moveToPrevOnEsc: function moveToPrevOnEsc() {
		var thisApp = this;

		$('html').keyup(function (event) {
			if (thisApp.activeId && thisApp.stepsArr.indexOf(thisApp.activeId) > 0 && (event.key === 'Escape' || event.key === 'Esc')) {
				thisApp.moveToPrev();
			}
		});
	},
	reset: function reset() {
		this.moveTo(this.initialId);
	}
};