'use strict';

app.popupCustom = {
	initEl: '.js-custom-popup',
	init: function init() {
		var _this = this;

		app.common.initScript('jquery.fancybox.min', 'fancybox', function () {
			_this.popup();
		});
		app.common.initStyle('jquery.fancybox.min');
	},
	popup: function popup() {
		var $html = $('html');
		var $initEl = $(this.initEl);
		if ($initEl.length) {
			$initEl.fancybox({
				animationDuration: 600,
				closeExisting: false,
				baseClass: 'fancybox-custom-popup',
				buttons: ['close'],
				touch: false,
				smallBtn: true,
				beforeShow: function beforeShow() {
					$html.addClass('show-popup').addClass('show-custom-popup');
					if ($('#js-fp').length || $('#js-fp-product').length) {
						fullpage_api.setAllowScrolling(false);
					}
				},
				afterShow: function afterShow() {
					$html.addClass('complete-popup');
				},
				beforeClose: function beforeClose() {
					$html.removeClass('show-popup');
				},
				afterClose: function afterClose() {
					$html.removeClass('complete-popup').removeClass('show-custom-popup');
					if ($('#js-fp').length || $('#js-fp-product').length) {
						fullpage_api.setAllowScrolling(true);
					}
				}
			});
		}
	}
};