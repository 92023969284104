'use strict';

app.accident = {
	scanVideo: $('video', $('.accident'))[0],
	playVideo: function playVideo() {
		var self = this;

		if (self.scanVideo) {
			var interval = setInterval(function () {
				if (self.scanVideo.readyState > 0) {
					clearInterval(interval);

					self.scanVideo.play();
				}
			}, 200);
		}
	},
	resetVideo: function resetVideo() {
		var self = this;

		if (self.scanVideo) {
			var interval = setInterval(function () {
				if (self.scanVideo.readyState > 0) {
					clearInterval(interval);

					self.scanVideo.pause();
					self.scanVideo.currentTime = 0;
				}
			}, 200);
		}
	}
};