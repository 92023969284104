'use strict';

app.philosophy = {
	initialIndex: 0,
	initEl: '.js-philosophy',
	slider: '.js-philosophy-slider',
	tagsItem: '.js-philosophy-tag',
	init: function init() {
		var _this = this;

		app.common.initScript('swiper.min', 'Swiper', function () {
			var $initEl = $(_this.initEl);
			if ($initEl.length) {
				_this.defineInitialIndex();
				_this.createSlider();
			}
		});

		app.common.initStyle('swiper.min');
	},
	defineInitialIndex: function defineInitialIndex() {
		var activeTagIdx = $(this.tagsItem).filter('.active').last().index(this.tagsItem);
		var activeSlideIdx = $('.swiper-slide', $(this.slider)).filter('.active').last().index();
		if (activeTagIdx >= 0) {
			this.initialIndex = activeTagIdx;
		} else if (activeSlideIdx >= 0) {
			this.initialIndex = activeSlideIdx;
		}
	},
	setActiveTag: function setActiveTag(id) {
		$(this.tagsItem).removeClass('active').filter('[data-id="' + id + '"]').addClass('active');
	},
	toggleTag: function toggleTag(id) {
		var activeId = id ? id : $(this.tagsItem).eq(this.initialIndex).attr('data-id');
		this.setActiveTag(activeId);
	},
	createSlider: function createSlider() {
		var self = this;
		var $curSlider = $(this.slider);
		var $prevEl = $curSlider.find('.js-philosophy-slider__button-prev');
		var $nextEl = $curSlider.find('.js-philosophy-slider__button-next');
		var tagToggleTimeout = void 0;

		var sliderInstance = new Swiper($curSlider.find('.swiper-container'), {
			init: false,
			initialSlide: this.initialIndex,
			slidesOffsetBefore: 100,
			watchSlidesVisibility: true,
			direction: 'vertical',
			slidesPerView: 'auto',
			freeMode: true,
			slideActiveClass: 'active',
			keyboard: {
				enable: true
			},

			navigation: {
				prevEl: $prevEl,
				nextEl: $nextEl
			},

			mousewheel: true,
			on: {
				init: function init() {
					self.toggleTag(sliderInstance.realIndex);
				},
				slideChange: function slideChange() {
					clearTimeout(tagToggleTimeout);

					tagToggleTimeout = setTimeout(function () {
						// self.toggleTag(sliderInstance.realIndex);
						self.toggleTag($(sliderInstance.slides[sliderInstance.realIndex]).attr('data-for'));
					}, 250);
				}
			}
		});

		sliderInstance.on('init', function () {
			$curSlider.addClass('is-loaded');
		});

		sliderInstance.init();
	}
};