'use strict';

app.micro = {
	initEl: '.js-micro',
	scenePart: '.js-micro-scene-part',
	partsListLink: '.js-micro-parts a',
	eventsArr: [],
	dur: 300,
	parts: [1, 2, 3, 4],
	tweenScenesPart: {
		scene0: {
			part1: {
				container: {
					xPercent: 15.14,
					yPercent: -17.15
				},
				figure: {
					scale: 0.158
				}
			},
			part2: {
				container: {
					xPercent: 9.6,
					yPercent: -15.3
				},
				figure: {
					scale: 0.132
				}
			},
			part3: {
				container: {
					xPercent: 7.98,
					yPercent: -12.19
				},
				figure: {
					scale: 0.088
				}
			},
			part4: {
				container: {
					xPercent: -17.78,
					yPercent: -6.1
				},
				figure: {
					scale: 0.088
				}
			}
		},
		scene1: {
			part1: {
				container: {
					xPercent: -50,
					yPercent: -50
				},
				figure: {
					scale: 1
				}
			},
			part2: {
				container: {
					xPercent: -84.74,
					yPercent: -38.1
				},
				figure: {
					scale: 0.834
				}
			},
			part3: {
				container: {
					xPercent: -95.16,
					yPercent: -18.83
				},
				figure: {
					scale: 0.56
				}
			},
			part4: {
				container: {
					xPercent: -238.02, // ?
					yPercent: -41.26 // ?
				},
				figure: {
					scale: 0.56 // ?
				}
			}
		},
		scene2: {
			part1: {
				container: {
					xPercent: -8.2,
					yPercent: -63.8
				},
				figure: {
					scale: 1.19
				}
			},
			part2: {
				container: {
					xPercent: -50,
					yPercent: -50
				},
				figure: {
					scale: 1
				}
			},
			part3: {
				container: {
					xPercent: -62.44,
					yPercent: -26.83
				},
				figure: {
					scale: 0.67
				}
			},
			part4: {
				container: {
					xPercent: -242, // ?
					yPercent: 47 // ?
				},
				figure: {
					scale: 0.67 // ?
				}
			}
		},
		scene3: {
			part1: {
				container: {
					xPercent: 30.8,
					yPercent: -105.5
				},
				figure: {
					scale: 1.78
				}
			},
			part2: {
				container: {
					xPercent: -31.4,
					yPercent: -84.6
				},
				figure: {
					scale: 1.492
				}
			},
			part3: {
				container: {
					xPercent: -50,
					yPercent: -50
				},
				figure: {
					scale: 1
				}
			},
			part4: {
				container: {
					xPercent: -355, // ?
					yPercent: 55 // ?
				},
				figure: {
					scale: 1 // ?
				}
			}
		},
		scene4: {
			part1: {
				container: {
					xPercent: 293,
					yPercent: -210
				},
				figure: {
					scale: 1.78
				}
			},
			part2: {
				container: {
					xPercent: 250,
					yPercent: -190
				},
				figure: {
					scale: 1.49
				}
			},
			part3: {
				container: {
					xPercent: 218,
					yPercent: -164
				},
				figure: {
					scale: 1
				}
			},
			part4: {
				container: {
					xPercent: -50,
					yPercent: -50
				},
				figure: {
					scale: 1
				}
			}
		}
	},
	tweenScenesFull: {
		scene0: {
			container: {
				xPercent: -50,
				yPercent: -50
			},
			figure: {
				scale: 1
			}
		},
		scene1: {
			container: {
				xPercent: -460.1,
				yPercent: -256.8
			},
			figure: {
				scale: 6.3
			}
		},
		scene2: {
			container: {
				xPercent: -496.5,
				yPercent: -310.1
			},
			figure: {
				scale: 7.5
			}
		},
		scene3: {
			container: {
				xPercent: -698.2,
				yPercent: -472.9
			},
			figure: {
				scale: 11.2
			}
		},
		scene4: {
			container: {
				xPercent: -385.4,
				yPercent: -585.7
			},
			figure: {
				scale: 11.2
			}
		}
	},
	init: function init() {
		var _this = this;

		app.common.initScript('EasePack.min', 'EasePack', function () {
			app.common.initScript('gsap.min', 'gsap', function () {
				if ($(_this.initEl).length) {
					_this.setStartPosition();
					_this.zoom();
					_this.changeParts();
				}
			});
		});
		app.common.initScript('jquery.matchHeight-min', 'matchHeight', function () {
			var $item = $('.micro__functions, .micro__parts');
			if ($item.length) {
				_this.equalHeight($item);
			}
		});
	},
	setStartPosition: function setStartPosition() {
		// Set start position of full scene
		this.tweenFull(0, 'set');

		// Set start position of high resolution parts
		this.hideParts(this.parts, 0, 'set');
	},
	zoom: function zoom() {
		var self = this;

		$(self.initEl).on('click', '.js-micro-zoom', function (e, trigger) {
			e.preventDefault();
			$(self.initEl).addClass('micro-zoom');

			if (!trigger) {
				self.saveEvents(e);
			}

			$(self.initEl).find('.micro__parts-titles-li').eq(0).find('a').trigger('click');
		});
	},
	changeParts: function changeParts() {
		var self = this;
		var $initEl = $(self.initEl);

		$initEl.on('click', self.partsListLink, function (e, trigger) {
			e.preventDefault();
			var $curEl = $(this);

			// Current element
			$(self.partsListLink, $initEl).removeClass('active');
			$curEl.addClass('active');

			// Current part
			var part = $curEl.attr('data-part');
			$initEl.attr('data-current-part', 'part' + part);

			// Current scene
			var scene = $curEl.attr('data-scene');
			$initEl.attr('data-current-scene', 'scene' + scene);

			// Filters
			var id = $curEl.attr('href').substr(1);
			$('[data-filter]').removeClass('active').filter('[data-filter*="' + id + '"]').addClass('active');

			// Save event
			if (!trigger) {
				self.saveEvents(e, id);
			}

			// Active part scene
			$(self.scenePart).removeClass('active');
			$('#micro-scene-part-' + part).addClass('active');

			var otherParts = self.parts.filter(function (i) {
				return i !== Number(part);
			});

			// Animate full scene
			self.tweenFull(Number(scene), 'to');

			// Show current part
			self.showPart(Number(part), Number(scene));

			// Hide other parts
			self.hideParts(otherParts, Number(scene), 'to');
		});

		$initEl.on('click', '.js-micro-back', function (e) {
			e.preventDefault();

			$('.js-micro-next').prop('disabled', false);

			if (self.eventsArr.length > 2) {
				self.removeEvents();

				$initEl.trigger(self.eventsArr[self.eventsArr.length - 1].event, true);
			} else {
				self.reset();
			}
		});

		$initEl.on('click', '.js-micro-next', function (e) {
			e.preventDefault();
			var $curBtn = $(this);

			var lastId = self.eventsArr[self.eventsArr.length - 1].id;

			if (lastId) {
				var $nextLi = $('[href="#' + lastId + '"]').parent().next();

				$nextLi.find('a').trigger('click');

				if ($nextLi.index() >= $(self.partsListLink, $initEl).length - 1) {
					$curBtn.prop('disabled', true);
				}
			}
		});
	},
	tweenScene: function tweenScene(el, options, state) {
		switch (state) {
			case 'hide':
				state = { opacity: 0 };
				break;
			case 'show':
				state = { opacity: 1 };
				break;
			default:
				state = {};
		}

		var defaultOptions = {
			duration: this.dur / 1000
		};

		gsap.to(el, $.extend({}, defaultOptions, state, options));
	},
	tweenSceneSet: function tweenSceneSet(el, options, state) {
		switch (state) {
			case 'hide':
				state = { opacity: 0 };
				break;
			case 'show':
				state = { opacity: 1 };
				break;
			default:
				state = {};
		}

		var defaultOptions = {
			opacity: state,
			transformOrigin: 'left top'
		};

		gsap.set(el, $.extend({}, defaultOptions, state, options));
	},
	tweenFull: function tweenFull(numScene, type) {
		var self = this;

		var $el = $('.js-micro-scene-full');
		if (type === 'set') {
			self.tweenSceneSet($el, self.tweenScenesFull['scene' + numScene].container);
			self.tweenSceneSet($el.children('div'), self.tweenScenesFull['scene' + numScene].figure);
		} else if (type === 'to') {
			self.tweenScene($el, self.tweenScenesFull['scene' + numScene].container);
			self.tweenScene($el.children('div'), self.tweenScenesFull['scene' + numScene].figure);
		} else {
			console.error('You must specify the type of tween');
		}
	},
	hideParts: function hideParts(arrParts, numScene, type) {
		var self = this;

		for (var i = 0; i < arrParts.length; i++) {
			var $el = $('#micro-scene-part-' + arrParts[i]);
			if (type === 'set') {
				self.tweenSceneSet($el, self.tweenScenesPart['scene' + numScene]['part' + arrParts[i]].container);
				self.tweenSceneSet($el.children('div'), self.tweenScenesPart['scene' + numScene]['part' + arrParts[i]].figure, 'hide');
			} else if (type === 'to') {
				self.tweenScene($el, self.tweenScenesPart['scene' + numScene]['part' + arrParts[i]].container);
				self.tweenScene($el.children('div'), self.tweenScenesPart['scene' + numScene]['part' + arrParts[i]].figure, 'hide');
			} else {
				console.error('You must specify the type of tween');
			}
		}
	},
	showPart: function showPart(numPart, numScene) {
		var self = this;

		var $el = $('#micro-scene-part-' + numPart);
		self.tweenScene($el, self.tweenScenesPart['scene' + numScene]['part' + numPart].container);
		self.tweenScene($el.children('div'), self.tweenScenesPart['scene' + numScene]['part' + numPart].figure, 'show');
	},
	removeEvents: function removeEvents() {
		this.eventsArr.splice(-1, 1);
	},
	saveEvents: function saveEvents(e, id) {
		var el = {};

		if (e) {
			el.event = e;
		}
		if (id) {
			el.id = id;
		} else {
			el.id = null;
		}

		this.eventsArr.push(el);
	},
	reset: function reset() {
		$(this.scenePart).removeClass('active');

		var $initEl = $(this.initEl);

		$initEl.removeClass('micro-zoom').attr('data-current-part', '').attr('data-current-scene', 'scene0');

		$(this.partsListLink, $initEl).removeClass('active');

		this.eventsArr = [];

		// Set start position of full scene
		this.tweenFull(0, 'to');

		// Set start position of high resolution parts
		this.hideParts([1, 2, 3, 4], 0, 'to');
	},
	equalHeight: function equalHeight($el) {
		$el.matchHeight();
	}
};