'use strict';

app.common = {
	name: 'common',
	description: 'application support functions',
	init: function init() {
		this.initScript('svg4everybody.min', 'svg4everybody', function () {
			svg4everybody();
		});
	},
	addScriptFile: function addScriptFile(nameFile, callback) {
		var body = document.getElementsByTagName('body')[0];
		var script = document.createElement('script');
		script.onload = function () {
			return callback && callback();
		};
		script.src = app.pathToLibsFiles + '/js/' + nameFile + '.js';
		body.appendChild(script);
	},
	addStyleFile: function addStyleFile(nameFile) {
		var appCss = document.getElementById('app-css');
		var link = document.createElement('link');
		link.rel = 'stylesheet';
		link.href = app.pathToLibsFiles + '/css/' + nameFile + '.css';
		appCss.before(link);
	},
	initScript: function initScript(nameFile, nameLib, callback) {
		var hasLibs = null;
		$('script').each(function () {
			var str = $(this).attr('src');
			if (str) {
				if (str.indexOf(nameFile) >= 0) {
					hasLibs = true;
				}
			}
		});
		if (!hasLibs) {
			this.addScriptFile(nameFile, callback);
		} else {
			var timerId = setInterval(function () {
				if ($()[nameLib] || window[nameLib]) {
					callback();
					clearInterval(timerId);
				}
			}, 1);
		}
	},
	initStyle: function initStyle(nameFile) {
		var hasLibs = null;
		$('link').each(function () {
			var str = $(this).attr('href');
			if (str) {
				if (str.indexOf(nameFile) >= 0) {
					hasLibs = true;
				}
			}
		});
		if (!hasLibs) {
			this.addStyleFile(nameFile);
		}
	},
	setViewport: function setViewport() {
		app.common.initScript('mobile-detect.min', 'MobileDetect', function () {
			var md = new MobileDetect(window.navigator.userAgent);
			if (md.mobile()) {
				$('#viewport').attr('content', 'width=1280');
			}
		});
	},
	initLazyLoad: function initLazyLoad() {
		app.common.initScript('lazyload.min', 'LazyLoad', function () {
			new LazyLoad({
				elements_selector: '.js-lazy-load'
			});
		});
	}
};