'use strict';

app.vote = {
	initEl: '.js-vote',
	opener: '.js-vote-opener',
	panel: '.js-vote-panel',
	isOpen: false,
	init: function init() {
		if ($(this.initEl).length) {
			this.run();
		}
	},
	run: function run() {
		var self = this;

		$(self.opener).on('click', function (e) {
			e.preventDefault();

			if (!self.isOpen) {
				self.addActiveClass();
			} else {
				self.removeActiveClass();
			}
		});

		var $html = $('html');

		$html.on('click', function (event) {
			if (self.isOpen && !$(event.target).closest(self.initEl).length) {
				self.removeActiveClass();
			}
		});

		$html.keyup(function (event) {
			if (self.isOpen && event.keyCode === 27) {
				self.removeActiveClass();
			}
		});
	},
	addActiveClass: function addActiveClass() {
		this.isOpen = true;
		$(this.initEl).add(this.opener).add(this.panel).addClass('is-open');
	},
	removeActiveClass: function removeActiveClass() {
		this.isOpen = false;
		$(this.initEl).add(this.opener).add(this.panel).removeClass('is-open');
	}
};