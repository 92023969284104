'use strict';

app.projectsRegions = {
	options: '.js-projects-options',
	init: function init() {
		var $options = $(this.options);

		if ($options.length) {
			this.positionOptions();
		}
	},
	positionOptions: function positionOptions() {
		var thisApp = this;
		var $item = $(thisApp.options).parent();

		$item.on('mouseenter', function () {
			var $curItem = $(this);
			$(thisApp.options, $curItem).css({
				'max-height': $curItem.closest('section').innerHeight() - $curItem.offset().top - 10,
				overflow: 'auto'
			});
		});
	}
};