'use strict';

app.home = {
	initEl: '#js-fp',
	sectionEl: '#js-fp > section',
	nextBtnEl: '.js-fp-next',
	duration: 500,
	init: function init() {
		var _this = this;

		app.common.initScript('fullpage.min', 'fullpage', function () {
			if ($(_this.initEl).length) {
				_this.run();
				_this.nextSection();
			}
		});
		app.common.initStyle('fullpage.min');
	},
	run: function run() {
		var self = this;
		var scrollStep = void 0;
		var timeoutVisualSet = void 0;
		$(this.initEl).fullpage({
			sectionSelector: self.sectionEl,
			anchors: self.historyAnchors(),
			recordHistory: false,
			keyboardScrolling: false,
			touchSensitivity: 10, // default - 5
			verticalCentered: false,
			scrollingSpeed: self.duration,
			licenseKey: '11111111-11111111-11111111-11111111',
			navigation: true,
			navigationPosition: 'right',
			normalScrollElements: '.js-projects-options',
			afterLoad: function afterLoad(origin, destination) {
				$(self.sectionEl).removeClass('animation-end');

				// Step scroll
				clearTimeout(scrollStep);
				scrollStep = setTimeout(function () {
					fullpage_api.setAllowScrolling(true);
				}, self.duration);

				var $destinationEl = $(destination.item);
				var $originEl = $(origin.item);

				$destinationEl.addClass('animation-end');
				if (destination.isLast && $destinationEl.hasClass('fp-auto-height')) {
					$(origin.item).addClass('animation-end');
				}

				clearTimeout(timeoutVisualSet);

				timeoutVisualSet = setTimeout(function () {
					// Play accident video
					var $accident = $destinationEl.find('.accident');
					if ($accident.length) {
						app.accident.playVideo();
					}
				}, 50);

				// Reset accident video
				var $accident = $originEl.find('.accident');
				if ($accident.length) {
					app.accident.resetVideo();
				}
			},
			onLeave: function onLeave(origin, destination) {
				fullpage_api.setAllowScrolling(false);

				$(self.sectionEl).removeClass('animation-start');

				var $destinationEl = $(destination.item);
				var $originEl = $(origin.item);

				$destinationEl.addClass('animation-start');
				if (destination.isLast && $destinationEl.hasClass('fp-auto-height')) {
					$originEl.addClass('animation-start');
				}

				if (app.scan) {
					app.scan.reset();
				}

				if (app.macro) {
					app.macro.reset();
				}

				if (app.micro) {
					app.micro.reset();
				}

				$(self.nextBtnEl).toggleClass('hide-anim', destination.isLast);
			},
			afterRender: function afterRender() {
				$('.js-fp-first').on('click', function (e) {
					fullpage_api.moveTo(1);
					e.preventDefault();
				});
			}
		});
	},
	historyAnchors: function historyAnchors() {
		var anchors = [];

		$.each($(this.sectionEl), function () {
			anchors.push($(this).attr('data-anchor'));
		});

		return anchors;
	},
	nextSection: function nextSection() {
		$(this.nextBtnEl).on('click', function (e) {
			e.preventDefault();
			fullpage_api.moveSectionDown();
		});
	}
};