'use strict';

app.services = {
	initEl: '.js-services',
	numeric: '.js-services-numeric',
	numericItem: '.js-services-numeric a',
	prev: '.js-service-prev',
	next: '.js-service-next',
	mods: {
		active: 'active'
	},
	partsArr: [],
	mousewheelThreshold: 180,
	init: function init() {
		var _this = this;

		app.common.initScript('jquery.mousewheel', 'mousewheel', function () {
			var $initEl = $(_this.initEl);
			if ($initEl.length) {
				_this.createPartArr();
				_this.defineActiveIdOnInit();
				_this.setActiveOnInit();
				_this.changeParts();
				_this.moveToPrevOnEsc();
				$initEl.addClass('services-initialized');
			}
		});
	},
	createPartArr: function createPartArr() {
		var thisApp = this;

		if (!thisApp.partsArr.length) {
			$.each($(thisApp.numericItem), function () {
				var href = $(this).attr('href');
				thisApp.partsArr.push(href ? href.substr(1) : null);
			});
		}
	},
	defineActiveIdOnInit: function defineActiveIdOnInit() {
		var activeId = $(this.numericItem).filter('.active').last().attr('href');
		this.activeId = this.initialId = activeId && activeId.length ? activeId : this.partsArr[0];
	},
	setActiveOnInit: function setActiveOnInit() {
		this.moveTo();
	},
	examineOnCompleted: function examineOnCompleted() {
		$(this.initEl).add($('html')).toggleClass('services-completed', this.completed);
	},
	moveTo: function moveTo(id) {
		this.activeId = id || this.initialId;
		this.activeIndex = this.partsArr.indexOf(this.activeId);

		$(this.numericItem).removeClass('active').filter('[href="#' + this.activeId + '"]').addClass('active');

		$('[data-part]').removeClass('active').filter('[data-part="' + this.activeId + '"]').addClass('active');

		$(this.initEl).attr('data-services-active-part', this.activeIndex + 1);

		this.completed = false;
		this.examineOnCompleted();
	},
	moveToNext: function moveToNext() {
		var nextIdx = this.activeIndex + 1;
		var nextId = this.partsArr[nextIdx];

		if (nextIdx > this.partsArr.length - 1) {
			this.completed = true;
			this.examineOnCompleted();
			this.resetParts();
		} else {
			this.moveTo(nextId);
		}
	},
	moveToPrev: function moveToPrev() {
		var prevIdx = this.activeIndex - 1;
		var prevId = this.completed ? this.partsArr[this.partsArr.length - 1] : this.partsArr[prevIdx];

		this.moveTo(prevId);
	},
	changeParts: function changeParts() {
		var thisApp = this;
		var $initEl = $(thisApp.initEl);

		$initEl.on('click', thisApp.numericItem, function (event) {
			event.preventDefault();
			var $curEl = $(this);

			if (!$curEl.hasClass('active')) {
				var id = $curEl.attr('href').substr(1);
				thisApp.moveTo(id);
			} else if (event.shiftKey) {
				thisApp.moveToPrev();
			} else {
				thisApp.moveToNext();
			}
		});

		$initEl.on('click', thisApp.next, function (event) {
			event.preventDefault();
			thisApp.moveToNext();
		});

		$initEl.on('click', thisApp.prev, function (event) {
			event.preventDefault();
			thisApp.moveToPrev();
		});

		var timeStampPrev = 0;

		$('html').on('mousewheel', function (event) {
			var dynamics = event.timeStamp - timeStampPrev;
			timeStampPrev = event.timeStamp;

			if (dynamics > thisApp.mousewheelThreshold) {
				if (event.deltaY < 0) {
					thisApp.moveToNext();
				}

				if (event.deltaY > 0) {
					thisApp.moveToPrev();
				}
			}
		});
	},
	moveToPrevOnEsc: function moveToPrevOnEsc() {
		var thisApp = this;

		$('html').keyup(function (event) {
			if (thisApp.activeId && thisApp.partsArr.indexOf(thisApp.activeId) > 0 && (event.key === 'Escape' || event.key === 'Esc')) {
				thisApp.moveToPrev();
			}
		});
	},
	resetParts: function resetParts() {
		$(this.numericItem).removeClass('active');

		$('[data-part]').removeClass('active');

		$(this.initEl).removeAttr('data-services-active-part');
	}
};